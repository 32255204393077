import { DayMoments, EntitySeance, OpeningHours, SeanceInscription } from '@/types/youth'

import { ceiledTime, compareTimes, diffTimes, addMinutes, removeMinutes } from '@/utils/time'

export function getMaxArrivalAt(openingHours: OpeningHours): string {
  if (openingHours.maxArrivalAt) {
    return openingHours.maxArrivalAt
  } else {
    return openingHours.closingAt
  }
}

export function getMinDepartureAt(seance: EntitySeance, openingHours: OpeningHours): string {
  if (
    seance.lunch && !seance.afternoon && openingHours.lunchEndsAt && openingHours.fixedLunch
  ) {
    return openingHours.lunchEndsAt
  } else if (openingHours.minDepartureAt) {
    return openingHours.minDepartureAt
  } else {
    return openingHours.openingAt
  }
}
export function getClosingAt(seance: EntitySeance, openingHours: OpeningHours): string {
  if (seance.lunch && !seance.afternoon && openingHours.lunchEndsAt) {
    return openingHours.lunchEndsAt
  } else {
    return openingHours.closingAt
  }
}

export function getOpeningAt2(seance: EntitySeance, openingHours: OpeningHours): string {
  if (seance.lunch && !seance.morning && openingHours.lunchStartsAt) {
    return openingHours.lunchStartsAt
  } else {
    return openingHours.openingAt2
  }
}

export function getMaxArrivalAt2(seance: EntitySeance, openingHours: OpeningHours): string {
  if (
    seance.lunch && !seance.morning && openingHours.lunchStartsAt && openingHours.fixedLunch
  ) {
    return openingHours.lunchStartsAt
  } else if (openingHours.maxArrivalAt2) {
    return openingHours.maxArrivalAt2
  } else {
    return openingHours.closingAt2
  }
}

export function getMinDepartureAt2(openingHours: OpeningHours): string {
  if (openingHours.minDepartureAt2) {
    return openingHours.minDepartureAt2
  } else {
    return openingHours.openingAt2
  }
}

function roundAfter(currentHour: string, fromTime: string, step: number): string {
  let diffInSeconds: number = diffTimes(currentHour, fromTime)
  if (diffInSeconds < 0) {
    diffInSeconds = 0
  }
  const stepInSeconds: number = 60 * step
  const roundedDiffInMinutes = Math.round(diffInSeconds / stepInSeconds) * step
  // console.log('>roundAfter', diffInSeconds, roundedDiffInMinutes)
  return addMinutes(fromTime, roundedDiffInMinutes)
}

function roundBefore(currentHour: string, fromTime: string, step: number): string {
  let diffInSeconds: number = diffTimes(fromTime, currentHour) - 1
  if (diffInSeconds < 0) {
    diffInSeconds = 0
  }
  const stepInSeconds: number = 60 * step
  const roundedDiffInMinutes = Math.round(diffInSeconds / stepInSeconds) * step
  // console.log('>roundBefore', diffInSeconds, roundedDiffInMinutes, removeMinutes(fromTime, roundedDiffInMinutes))
  return removeMinutes(fromTime, roundedDiffInMinutes)
}

export function getSafeClocking(
  openingHours: OpeningHours,
  inscription: SeanceInscription,
  currentHour: string,
  dayMoment: DayMoments
): string {
  // retourne l'heure pointée, arrondie et dans les limites
  if (openingHours.directCeil > 0) {
    if ((dayMoment === DayMoments.Closing) || (dayMoment === DayMoments.Closing2)) {
      currentHour = ceiledTime(currentHour, openingHours.directLimit, openingHours.directCeil)
    } else {
      const arrivalLimit = openingHours.directCeil - openingHours.directLimit + 1
      currentHour = ceiledTime(currentHour, arrivalLimit, openingHours.directCeil)
    }
  }

  switch (dayMoment) {
    case DayMoments.Opening:
      const openingAt = openingHours.openingAt
      if (openingAt && (compareTimes(currentHour, openingAt) > 0)) {
        currentHour = openingAt
      }
      if (openingHours.directCeil === 0) {
        currentHour = roundAfter(currentHour, openingAt, openingHours.step)
      }
      const maxArrivalAt = getMaxArrivalAt(openingHours)
      if (maxArrivalAt && (compareTimes(currentHour, maxArrivalAt) < 0)) {
        currentHour = maxArrivalAt
      }
      if (inscription.leftAt && (compareTimes(currentHour, inscription.leftAt) < 0)) {
        currentHour = inscription.leftAt
      }
      break
    case DayMoments.Closing:
      const closingAt = getClosingAt(inscription.seance, openingHours)
      if (closingAt && (compareTimes(currentHour, closingAt) < 0)) {
        currentHour = closingAt
      }
      if (openingHours.directCeil === 0) {
        currentHour = roundBefore(currentHour, closingAt, openingHours.step)
      }
      const minDepartureAt = getMinDepartureAt(inscription.seance, openingHours)
      if (minDepartureAt && (compareTimes(currentHour, minDepartureAt) > 0)) {
        currentHour = minDepartureAt
      }
      if (inscription.arrivedAt && (compareTimes(currentHour, inscription.arrivedAt) > 0)) {
        currentHour = inscription.arrivedAt
      }
      break
    case DayMoments.Opening2:
      const openingAt2 = getOpeningAt2(inscription.seance, openingHours)
      if (openingAt2 && (compareTimes(currentHour, openingAt2) > 0)) {
        currentHour = openingAt2
      }
      if (openingHours.directCeil === 0) {
        currentHour = roundAfter(currentHour, openingAt2, openingHours.step)
      }
      const maxArrivalAt2 = getMaxArrivalAt2(inscription.seance, openingHours)
      if (maxArrivalAt2 && (compareTimes(currentHour, maxArrivalAt2) < 0)) {
        currentHour = maxArrivalAt2
      }
      if (inscription.leftAt2 && (compareTimes(currentHour, inscription.leftAt2) < 0)) {
        currentHour = inscription.leftAt2
      }
      break
    case DayMoments.Closing2:
      const closingAt2 = openingHours.closingAt2
      if (closingAt2 && (compareTimes(currentHour, closingAt2) < 0)) {
        currentHour = closingAt2
      }
      if (openingHours.directCeil === 0) {
        currentHour = roundBefore(currentHour, closingAt2, openingHours.step)
      }
      const minDepartureAt2 = getMinDepartureAt2(openingHours)
      if (minDepartureAt2 && (compareTimes(currentHour, minDepartureAt2) > 0)) {
        currentHour = minDepartureAt2
      }
      if (inscription.arrivedAt2 && (compareTimes(currentHour, inscription.arrivedAt2) > 0)) {
        currentHour = inscription.arrivedAt2
      }
      break
    case DayMoments.Opening3:
      const openingAt3 = openingHours.openingAt3
      if (openingAt3 && (compareTimes(currentHour, openingAt3) > 0)) {
        currentHour = openingAt3
      }
      if (openingHours.directCeil === 0) {
        currentHour = roundAfter(currentHour, openingAt3, openingHours.step)
      }
      if (inscription.leftAt3 && (compareTimes(currentHour, inscription.leftAt3) < 0)) {
        currentHour = inscription.leftAt3
      }
      break
    case DayMoments.Closing3:
      const closingAt3 = openingHours.closingAt3
      if (closingAt3 && (compareTimes(currentHour, closingAt3) < 0)) {
        currentHour = closingAt3
      }
      if (openingHours.directCeil === 0) {
        currentHour = roundBefore(currentHour, closingAt3, openingHours.step)
      }
      if (inscription.arrivedAt3 && (compareTimes(currentHour, inscription.arrivedAt3) > 0)) {
        currentHour = inscription.arrivedAt3
      }
      break
  }
  return currentHour
}
